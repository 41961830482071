<template>
  <p class="subtitle-2 mb-3">
    <slot />
  </p>
</template>

<script>
export default {
  name: 'InputLabel',
};
</script>
